@import './_mixins';

@keyframes float {
  0% {
    transform: translateY(-6px);
  }
  50% {
    transform: translateY(6px);
  }
  100% {
    transform: translateY(-6px);
  }
}

@keyframes slide {
  0%{
    transform: translate3d(0, 0, 0);
  }
  100%{
    transform: translate3d(-1692px, 0, 0);
  }
}

@keyframes openPage {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes appearsTitleHomePageVideo {
  0% {
    opacity: 0;
    @include transform(translateY(50px));
  }
  100% {
    @include transform(translateY(0px));
    opacity: 1;
  }
}

@keyframes spring-effect {
  0% {
    transform: translateX(0px)  scale(1,1) ;
  }
  25% {
    transform: translateX(5px) scale(.9,1.1);
  }
  50% {
    transform: translateX(2px) scale(1.05,.95);
  }
  75% {
    transform: translateX(5px) scale(.9,1.1);
  }
  100% {
    transform: translateX(0px) scale(1,1) ;
  }
}

@keyframes appears {
  0% {
    transform: translateY(80px) scale(.8);
    opacity: 0;

  }
  100% {
    transform: translateY(0px) scale(1);
    opacity: 1;
  }
}

@keyframes disappears {
  0% {
    transform: translateY(0px) scale(1);
    opacity: 1;
  }
  100% {
    transform: translateY(-80px) scale(.8);
    opacity: 0;
  }
}

@keyframes fancy {
  0% {
	opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fancy-2 {
  0% {
	opacity: 0;
  }
  100% {
	opacity: 1;
  }
}

@-webkit-keyframes contentFadeInTopTitle {
   0% {
	 transform: translateY(70px) rotate(5deg);

	 opacity: 0
   }

  80% {
	transform: rotate(1deg);
  }

   100% {
	 transform: translateY(0) rotate(0deg);
	 opacity: 1
   }
 }

@-webkit-keyframes contentFadeInTopSubtitle {
  0% {
	transform: translateY(70px);
	opacity: 0;
  }
  100% {
	transform: translateY(0);
	opacity: 1
  }
}

@keyframes fadeInUp {
  from {
    transform: translate3d(0,40px,0)
  }

  to {
    transform: translate3d(0,0,0);
    opacity: 1
  }
}

@-webkit-keyframes fadeInUp {
  from {
    transform: translate3d(0,40px,0)
  }

  to {
    transform: translate3d(0,0,0);
    opacity: 1
  }
}

@keyframes dayBounce {
  0% {
    background-color: #ffffff;
  }
  50% {
    background-color: #f4f4f4;
  }
  100% {
    background-color: #ffffff;
  }
}
