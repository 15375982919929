@import '../../styles/_variables';
@import '../../styles/_mixins';
@import '../../styles/_animations';


.homepage-footer {
  user-select: none;
  font-family: $nunito;
  padding: 18px 0;
  z-index: 1000;
  background: hsla(0,0%,100%,.9);
  position:sticky;
  bottom: 0;
  img {
    flex: 1 0 auto;
  }
  .found{
    &.found-label{
      a{
        display: block;
        height: 21px;
        line-height: 21px;
      }
    }
  }
}
#home-page {
  .visible {

    .title {
      animation: appearsTitleHomePageVideo 1.2s ease-out forwards;
      opacity: 0;
    }
    .subtitle {
      animation: appearsTitleHomePageVideo 1.2s ease-out 0.7s forwards;
      opacity: 0;
    }
    &.animation1 {
      animation: appearsTitleHomePageVideo 1.2s ease-out forwards;
      opacity: 0;
    }
  }
}

@media (max-width: $md-size) {
    .homepage-footer {
      padding: 10px 0;

      .found {
        display: inline-flex;
        z-index: 10000;
        margin: auto;
        img {
          max-width: 100%;
          object-fit: contain;
        }
        &:not(:last-child){
          width: 30%;
          margin: 0 4px;
        }
        &:last-child{
          display: block;
          text-align: center;
          width: 100%;
        }
        &.found-label{
          margin-top: 10px;
          padding: 0;
          a{
            margin: auto;
            color: #8D9DB1;
            font-family: $proxima-nova;
            font-size: 14px;
            text-decoration: none;
            width: max-content;
          }
        }
      }
    }
}

@media (min-width: $md-size) {
    .homepage-footer {
      .found{
        display: inline-block;
        &:not(:first-child) {
          margin-left: 57px;
        }

        &.found-label{
          float:right;
          height: 100%;
          a{
            line-height: 21px;
            color: #8D9DB1;
            font-family: $proxima-nova;
            font-size: 14px;
            text-decoration: none;
            width: max-content;
          }
        }
      }
    }
}
