@import "../../styles/_variables";

#home-page{
  .bus{
  padding-top: 80px;
  margin-bottom: 40px;
  h2{
	font-size: 35px;
	font-weight: 300;
	line-height: 45px;
	text-shadow: 0 10px 30px 0 rgba(28,29,29,0.33);
	font-family: $proxima-nova;
	padding-bottom: 42px;
	opacity: 0;
  }
  h6{
	font-family: $nunito;
	color: rgba(22,43,36,0.5);
  }
  }
}

@media (min-width: $md-size) {
  #home-page {
	.bus {
	  .content {
		.info {
		  height: 542px;
		  position: relative;

		  .bus-content {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);

			h6 {
			  max-width: 463px;
			  font-size: 18px;
			  line-height: 33px;
			}
		  }
		}

		.image {
		  position: relative;

		  &:before {
			position: absolute;
			content: '';
			height: 542px;
			width: 970px;
			left: 75px;
			top: -20px;
			background-color: #162B24;
		  }
		}
	  }
	}
  }
}

@media (max-width: $md-size) {
  #home-page {
	.bus {
	  padding-top: 40px;
	  margin-bottom: 0;

	  h2 {
		line-height: 33px;
		font-size: 23px;
		padding-bottom: 25px;
	  }

	  .image {
		margin-top: 50px;
		background: url("../../assets/images/home/img_river.png") no-repeat bottom center;
		background-size: cover;
		height: 310px;
	  }
	}
  }
}
