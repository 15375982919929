@import '../../styles/_variables';



.custom-select{
  position: relative;
  width: 100%;
  .custom-select-icon{
    position: absolute;
    content: '';
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
    height: 18px;
    width: 18px;
    overflow: hidden;
    text-align: center;
  }
}
.css-1hwfws3{
  .css-1g6gooi,
  .css-151xaom-placeholder,
  .css-1qc4v11-singleValue{
    margin-left: 40px;
    color: rgba(20, 36, 40, 0.5);
  }
  .css-1qc4v11-singleValue{
    width: calc(100% - 40px);
  }
}

.css-kj6f9i-menu{
  margin-top: 40px;
  cursor: pointer;
}

.css-1xo8bgs-control{
  border-radius: 0;
  border: 1px solid $blue;
  box-shadow: none;
}

.css-1pcexqc-container,
.css-1uy2hiw-control{
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0;
  transition: all .4s ease;
  cursor: pointer;

  &:hover{
    background-color: rgba(20,36,40,0.05);
  }
}

.custom-placeholder {
  display: flex;

  .placeholder-icon {
    width: 25px;
    margin-left: 5px;
    margin-right: 10px;
    flex: 1 0 auto;

    display: flex;
    align-items: center;
    justify-content: center;

    img.ico-date {
      width: 23px;
      height: 25px;
    }

    img.ico-people {
      width: 23px;
      height: 23px;
    }
  }

  .placeholder-label {
    flex: 1 0 auto;
    font-size: 16px;
    font-weight: 500;
    line-height: 28px;
    color: rgba(20, 36, 40, 0.5);
  }
}


@media (max-width: $md-size) {
  .custom-placeholder {
    .placeholder-icon{
      margin-left: 0;
      margin-right: 5px;
    }
  }

  .css-1hwfws3{
    padding-left: 0 !important;
  }

  .custom-select{
    height: 100%;
    .custom-select-icon{
      left: 10px;
    }
    .css-1gxuo32-container{
      height: 45px;
      .css-bi9zhj-control,
      .css-hv2any-control{
        height: 100%;
        .css-1hwfws3{
          height: 100%;
        }
      }
    }
  }
  .css-1hwfws3{
    .css-1g6gooi,
    .css-151xaom-placeholder,
    .css-1qc4v11-singleValue{
      margin-left: 40px;
      color: rgba(20, 36, 40, 0.5);
    }
  }
}

@media (max-width: $sm-size) {
  .custom-select{
    background-color: #f3f4f4;
  }
}
