@import '../../styles/_variables';
@import '../../styles/_mixins';

#home-page {
  .heading {
    width: 100vw;


    .image-wrapper {
      color: $text-lighter;
      position: relative;

      .header-content {
        position: relative;
        height: 100%;
        z-index: 1000;

        .image-wrapper-logos {
          position: relative;
        }

        .image-wrapper-logo-powered {
          position: absolute;
          background: url('../../assets/images/home/amphibious_logo@3x.svg')
            no-repeat center center;
          background-size: contain;
          height: 62px;
          width: 193px;
          top: 30px;
          left: 150px;
        }

        .image-wrapper-content {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%,-50%);

          .title {
            animation: appearsTitleHomePageVideo 1.5s ease-out;
            width: 792px;
            text-align: center;
            h1 {
              font-family: $proxima-nova;
              line-height: 80px;
              font-size: 75px;
              font-weight: bold;
              text-shadow: 0 10px 30px 0 rgba(28,29,29,0.33);
            }
          }
        }
      }

      .bg-img {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: url('../../assets/images/home/img_header.jpg');
        background-repeat: no-repeat;
        background-size: cover;

        .dark-layer {
          height: 100%;
          width: 100%;
          z-index: 1;
          position: absolute;
          background-color: rgba(20, 36, 40, 0.4);
          opacity: 0.1;
        }
      }
    }
  }

  .booking-bar {
    position: relative;
    display: flex;
    font-family: $proxima-nova;
    color: rgba(20, 36, 40, 0.7);
    width: 100%;
    margin: auto;
    background-color: white;
    margin-top: -45px;
    z-index: 1000;
    border-radius: 0;
    box-shadow: 0 10px 30px 0 rgba(96, 125, 139, 0.13);


	.field{
	  cursor: pointer;
	  .css-1uy2hiw-control{
		transition: background-color .4s ease;
		cursor: pointer;
	  }

	  .css-1pcexqc-container{
		width: 100%;
	  }

      &.search {
        position: relative;
        input{
          padding-left: 50px;
        }
        &:before {
          position: absolute;
          content: '';
          top: 50%;
          left: 21px;
          transform: translateY(-50%);
          background: url('../../assets/icons/ico_search.svg') no-repeat
          center center;
          background-size: cover;
          height: 23px;
          width: 23px;
        }

        .css-1pcexqc-container{
          width: 100%;
          .css-151xaom-placeholder{
            width: 100%;
          }
        }

        .custom-placeholder{
          display: inline-flex;
          width: 100%;
          .placeholder-icon {
            padding-right: 20px;
          }
          .placeholder-label {
            padding-top: 4px;
          }
        }

      }

	}

    .book-experience-button {
      padding: 12px ;
      color: white;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      border: 0;
      width: 100%;
      height: auto;
      text-decoration: none;
      cursor: pointer;
    }

    .glasses-chooser {
      display: flex;

      .counter-decrease {
        padding-left: 32px;
        font-size: 24px;
        font-weight: 500;
        line-height: 28px;
      }

      .counter-value {
        padding-left: 32px;
        font-size: 17px;
        font-weight: 500;
        line-height: 28px;
      }

      .counter-increase {
        padding-left: 32px;
        font-size: 24px;
        font-weight: 500;
        line-height: 28px;
      }
    }

  }
}

@media (min-width: $md-size) {
  #home-page {
    .heading {
      height: 100vh;
      max-height: 750px;
      .image-wrapper {
        user-select: none;
        height: 100%;
        .bg-img {
          .container {
            height: 100%;
          }
        }
        .header-content {
          .image-wrapper-logos {
            &:before {
              position: absolute;
              content: '';
              background: url('../../assets/images/home/waves_01.svg') no-repeat
                center center;
              background-size: contain;
              height: 130.11px;
              width: 353.56px;
              top: -2px;
              left: -90px;
            }

            .image-wrapper-logo-powered {
              position: absolute;
              background: url('../../assets/images/home/amphibious_logo@3x.svg')
                no-repeat center center;
              background-size: contain;
			  height: 62px;
			  width: 191px;
              top: 8px;
              left: 0;
            }

            .image-wrapper-splash {
              position: absolute;
              background: url('../../assets/images/home/Splashtours_logo.svg')
              no-repeat center center;
              background-size: contain;
              height: 62px;
              width: 82px;
              top: 8px;
              left: 270px;
            }
          }
        }
      }
      .booking-bar{
		height: 80px;
        .field{
          height: 80px;
          padding: 17px 10px;

          &:not(:first-child){
            border-left: 1px solid rgba(20, 36, 40, .05);
            height: 100%;
          }

        }
        .book-experience-button{
          margin: 0 7px;
        }

      }
    }
  }
}

@media (max-width: $md-size) {
  #home-page {
    .heading {
      .image-wrapper {
        height: 375px;

        .header-content {
          height: 470px;
          min-height: 470px;

          .image-wrapper-logos {
            height: 110px;
            position: absolute;
            left: 50px;
            @include transform(translateX(-50%));

            &:before {
              content: '';
              position: absolute;
              background: url('../../assets/images/home/waves_01.svg') no-repeat
                center center;
              background-size: contain;
              height: 100px;
              width: 220px;
              top: -11px;
              left: 50%;
              @include transform(translateX(-50%));
            }
          }

          .image-wrapper-logo-powered {
            height: 36px;
            top: 10px;
            left: 50%;
            @include transform(translateX(-50%));
          }

          .image-wrapper-splash {
            position: absolute;
            background: url('../../assets/images/home/Splashtours_logo.svg')
            no-repeat center center;
            background-size: contain;
            height: 40px;
            width: 53px;;
            top: 8px;
            left: 110px;
          }

          .image-wrapper-content {
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            .title {
              h1 {
                font-size: 30px;
                line-height: 36px;
                font-weight: bold;
              }
            }
          }
        }

        .bg-img {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          background: url('../../assets/images/home/img_header.jpg');
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;

          .dark-layer {
            height: 100%;
            width: 100%;
            z-index: 1;
            position: absolute;
            background-color: rgba(20, 36, 40, 0.4);
            opacity: 0.1;
          }
        }
      }
    }
  }
}

@media (max-width: $md-size) and (min-width: $sm-size) {
  #home-page {
    .heading {
      .image-wrapper{
        .header-content {
          .image-wrapper-content {
            .title {
              h1{
                font-size: 45px ;
                line-height: 50px;
                margin: 0 2.5rem;
              }
            }
          }
        }

      }
      .booking-bar{
        .field{
          height: 52px;
          margin-top: 13px;
          margin-bottom: 13px;
          &:last-child{
            border-left: none;
			margin-bottom: 13px;
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  #home-page {
    .heading {
      .image-wrapper {

        .header-content {

          .image-wrapper-content {
            .title {
              width: 256px;
            }
          }
        }
      }

      .booking-bar{
      padding: 19px;
        .book-experience-button{
          padding: 12.5px;
        }
      .field{
        border-left: none;
        background-color: rgba(20,36,40,0.05);
        margin-top: 8px;
        .css-1uy2hiw-control{
		  padding: 4.5px 0;
        }
        ~:first-child{
          margin: 20px 0;
        }
        padding: 0;
      }
    }
    }
  }
}

@media (max-width: $sm-size) {
  #home-page {
    .heading {
      .image-wrapper {
        height: 375px;

        .header-content {
          height: 470px;
          min-height: 470px;

          .image-wrapper-logos {
            height: 110px;
            position: absolute;
            left: 50px;
            @include transform(translateX(-50%));

            &:before {
              content: '';
              position: absolute;
              background: url('../../assets/images/home/waves_01.svg') no-repeat
              center center;
              background-size: contain;
              height: 100px;
              width: 220px;
              top: -11px;
              left: 50%;
              @include transform(translateX(-50%));
            }
          }

          .image-wrapper-logo-powered {
            height: 36px;
            top: 10px;
            left: 50%;
            @include transform(translateX(-50%));
          }

          .image-wrapper-splash {
            position: absolute;
            background: url('../../assets/images/home/Splashtours_logo.svg')
            no-repeat center center;
            background-size: contain;
            height: 40px;
            width: 53px;;
            top: 8px;
            left: 110px;
          }

          .image-wrapper-content {
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            .title {
              h1 {
                font-size: 30px;
                line-height: 36px;
                font-weight: bold;
              }
            }
          }
        }

        .bg-img {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          background: url('../../assets/images/home/img_header.jpg');
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;

          .dark-layer {
            height: 100%;
            width: 100%;
            z-index: 1;
            position: absolute;
            background-color: rgba(20, 36, 40, 0.4);
            opacity: 0.1;
          }
        }
      }
      .booking-bar{
        .field{
          &.search{
            &:before{
              left: 9px;
              height: 18px;
              width: 20px;
              background-size: contain;
            }
          }
        }
      }
    }
  }
}
