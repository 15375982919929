.bright-mobile-slider {
  .slider {
    width: 100%;
    position: relative;
    display: block;
    overflow: hidden;

    .slides {
      width: 100%;
      height: 167%;
      position: absolute;
      left: 0;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;

      &.animated {
        transition: transform 0.3s ease;
      }

      .slide {
        position: absolute;
        top: 0;
        transform: scale(0.9);
        transition: padding 0.3s ease 0.6s, transform 0.3s ease,
          opacity 0.3s ease 0.6s;
        flex-shrink: 0;
        width: 60%;
        height: 60%;

        &.selected {
          transform: scale(1);
        }
      }
    }
  }

  .pagination {
    width: 100%;
    display: block;
    margin-top: 30px;
    margin-bottom: 60px;

    .circle {
      content: ' ';
      display: inline-block;
      width: 11px;
      height: 11px;
      background-color: black;
      border-radius: 50%;
      cursor: pointer;
      transition: background-color 0.3s;
      margin: 0 5px;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      -webkit-tap-highlight-color: transparent; /* For some Androids */

      &.selected {
        background-color: black;
      }
    }
  }
}

@media (min-width: 62em) {
  .bright-mobile-slider{
    position: relative;
    .controllers-wrapper{
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      .controllers{
        height: 100%;
        width: 100%;
        position: relative;
        .control{
          position: absolute;
          content: '';
          top: 40%;
          transform: translateY(-50%) scale(1);
          height: 45px;
          width: 45px;
          z-index: 100;
          opacity: 1;
          transition: all .6s ease;
          cursor: pointer;
          user-select: none;
          &.next{
            right: 25px;
            background: url("../../assets/icons/ico_button_right.svg") no-repeat center center;
            background-size: cover;
          }
          &.previous{
            left: 25px;
            background: url("../../assets/icons/ico_button_left.svg") no-repeat center center;
            background-size: cover;
          }
          &.hide{
            opacity: 0;
            pointer-events: none;
            transform: translateY(-50%) scale(.3);
          }
          &:hover{
            transform: translateY(-50%) scale(1.2);
          }
        }
      }
    }
  }
}
