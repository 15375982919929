@import '../styles/_variables';
@import '../styles/_mixins';

footer.site-footer {
  user-select: none;
  font-family: $nunito;
  color: white;
  background-color: $dark;
  z-index: 2;
  padding: 100px 0 40px 0;

  .footer-top {
    padding-bottom: 71px;

    .footer-column {
      color: white;

      .footer-column-title {
        font-weight: bold;
        font-size: 16px;
        line-height: 22px;
        padding-bottom: 20px;
        opacity: 0.8;
      }

      a {
        text-decoration: none;
        color: white;
        opacity: 0.6;
        transition: opacity 0.3s ease-in-out;

        &:hover {
          opacity: 1;
        }
      }

      ul {
        padding-top: 24px;

        li:not(:first-child) {
          padding-top: 24px;
        }
      }
    }

    .social-accounts {
      .icon {
        line-height: 0;
        font-size: 0;
        border: 1px solid rgba(255, 255, 255, 0.4);
        border-radius: 19px;
        transition: all .4s ease;

        &:hover{
          border: 1px solid rgba(255, 255, 255, 1);
        }

        &:not(:first-child) {
          margin-left: 15px;
        }
      }

      .youtube {
        width: 38px;
        height: 38px;
        background: url('../assets/images/footer/ico_youtube.svg') no-repeat
          center center;
      }

      .instagram {
        width: 38px;
        height: 38px;
        background: url('../assets/images/footer/ico_instagram.svg') no-repeat
          center center;
      }

      .facebook {
        width: 38px;
        height: 38px;
        background: url('../assets/images/footer/ico_facebook.svg') no-repeat
          center center;
      }
    }
  }

  .footer-bottom {
    padding-top: 36px;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    font-size: 15px;
    font-weight: 600;
    color: rgba(255, 255, 255, 0.6);
    line-height: 20px;

    a {
      text-decoration: none;
      color: rgb(255, 255, 255);
      opacity: 0.6;
      transition: opacity 0.3s ease-in-out;

      &:hover {
        opacity: 1;
      }
    }

    .i18n {
      display: flex;
      font-size: 13px;

      .lang {
        cursor: pointer;

        &:not(:first-child) {
          padding-left: 7px;
        }

        > :not(.active) {
          color: rgba(255, 255, 255, 0.4);
        }

        > .active {
          color: rgba(255, 255, 255, 0.8);
        }
      }
    }

    .links {
      padding-left: 36px;

      > a:not(:first-child) {
        padding-left: 36px;
      }
    }
  }
}

@media (max-width: $md-size) {
  footer.site-footer {
    padding: 59px 0 40px 0;

    .footer-top {
      .footer-column {
        &:not(first-child) {
          padding-top: 35px;
        }
      }

      .social-accounts {
        padding-top: 35px;
		a{
		  height: 56px !important;
		  width: 56px !important;
		  border-radius: 50% !important;
		}
      }
    }

	.footer-bottom{

      .i18n {
       display: none;
      }
      .links{
        padding-left: 0;
      }

	  .row{
		:first-child{
		  padding-bottom: 21px;
		}
	  }

	}
  }
}
