//fonts
$din: 'DIN Condensed', sans-serif;
$proxima-nova: 'proxima-nova', sans-serif;
$nunito: 'nunito', sans-serif;

//colors
$blue: #46bed6;
$dark: #142428;
$dirty: #e8f3f5;

//text-colors
$text-lighter: #ffffff;
$text-light: #62707c;
$text-grey: #4d4e4c;
$text-dark: #142428;
$text-blue: #62707c;
$text-error: #ED2226;

// Grid sizes
$sm-size: 48em; //768px
$md-size: 62em; //992px
$lg-size: 75em; //1200px
