@import '../../styles/_variables';

#home-page {
  .how-it-works {
    background-color: $dark;
    color: #ffffff;

    h2 {
      font-family: $proxima-nova;
      font-size: 30px;
      font-weight: normal;
      line-height: 40px;
      text-align: center;
    }

    .animation1 {
      opacity: 0;
    }

    .step {
      .content {
        .step-icon {
          padding-bottom: 24px;

          img{
            width: 57px;
            height: 48px;
            object-fit: contain;
          }

        }

        h4 {
          font-family: $proxima-nova;
        }

        p {
          font-family: $nunito;
          color: rgba(255, 255, 255, 0.5);
        }
      }
    }
  }
}

@media (min-width: $md-size) {
  #home-page {
    .how-it-works {
      h2 {
        padding: 120px 0;
      }

      .steps {
        padding-bottom: 120px;

        .step {
          &:not(:first-child) {
            .number {
              &:before {
                position: absolute;
                content: '';
                top: 0;
                left: 50%;
                transform: translateX(-50%);
                background-color: rgba(255, 255, 255, 0.4);
                width: 1px;
                height: 30%;
              }
            }
          }
          &:not(:last-child) {
            .number {
              &:after {
                position: absolute;
                content: '';
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
                background-color: rgba(255, 255, 255, 0.4);
                width: 1px;
                height: 30%;
              }
            }
          }

          .number {
            width: 100%;
            position: relative;
            .number-wrapper {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              font-size: 25px;
              line-height: 30px;
              text-shadow: 0 10px 30px 0 rgba(28, 29, 29, 0.33);
              font-family: $nunito;
            }
          }

          .content {
            margin-bottom: 25px;

            h4 {
              font-size: 25px;
              font-weight: 300;
              line-height: 35px;

              padding-bottom: 15px;
            }

            p {
              font-size: 16px;
              line-height: 24px;
              font-family: $nunito;
              color: rgba(255, 255, 255, 0.5);
            }
          }

          .content-cell {
            &.first-md {
              .content {
                text-align: right;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: $md-size) {
  #home-page {
    .how-it-works {
      h2 {
        padding: 60px 0 46px 0;
        font-size: 24px;
        font-weight: normal;
        line-height: 40px;
      }

      .steps {
        padding-bottom: 45px;

        .step {
          text-align: left;

		  &:not(:last-child) {
			.number {
			  &:after {
				position: absolute;
				content: '';
				top: 50px;
				left: 50%;
				transform: translateX(-50%);
				background-color: rgba(255, 255, 255, 0.4);
				width: 1px;
				height: calc(90% - 45px);
			  }
			}
		  }

          .number {
			width: 100%;
			position: relative;
            .number-wrapper{
			  font-family: $nunito;
			  font-size: 25px;
			  line-height: 30px;
			  text-shadow: 0 10px 30px 0 rgba(28,29,29,0.33);
			  color: #FFFFFF;
			  text-align: center;
            }
          }

          .content {
            margin-bottom: 25px;
            h4 {
              font-size: 22px;
              font-weight: 300;
              line-height: 29px;
              padding-bottom: 15px;
            }
            p {
              font-size: 16px;
              line-height: 24px;
            }
          }
        }
      }
    }
  }
}
