@import "../../../styles/variables";


.experience-item-link{
  width: 100%;
  text-decoration: none;
}

.experience-item {
  cursor: pointer;
  text-decoration: none;

  .experience-image-wrapper {
	height: 300px;
	position: relative;
	overflow: hidden;
	background-color: #DAE4E7;

	img {
	  height: 100%;
	  width: 100%;
	  object-fit: cover;
	  transition: all 0.4s ease;
	}

	.experience-price {
	  position: absolute;
	  padding: 8px;
	  left: 25px;
	  bottom: 25px;
	  min-width: 130px;
	  min-height: 33px;
	  background-color: white;
	  font-weight: 600;
	  font-size: 12px;
	  line-height: 18px;
	  color: $text-dark;

	  strong {
		font-size: 18px;
		font-weight: 600;
	  }
	}
  }

  .experience-title {
	padding-top: 22px;
	font-size: 18px;
	font-weight: 600;
	line-height: 28px;
	transition: color 0.4s ease;
	color: #132427;
	font-family: $proxima-nova;
	text-decoration: none;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
  }

  .experience-description {
	font-family: $nunito;
	font-size: 16px;
	font-weight: normal;
	color: rgba(20, 36, 40, 0.5);
	line-height: 23px;
	text-decoration: none;
  }

  a {
	text-decoration: none;
  }

  &:hover {
	.experience-title {
	  color: #46bed6;
	}

	.experience-image-wrapper {
	  img {
		transform: scale(1.08);
	  }
	}
  }
}


@media (max-width: $md-size) {
	.experience-item {
	  padding-bottom: 38px;
	  .btn{
		margin-top: 10px;
		padding-top: 8px;
		padding-bottom: 8px;
	  }
	}
}
