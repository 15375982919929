@import '../../styles/_variables';

#home-page {
  .experiences {
    font-family: $proxima-nova;
    color: $text-dark;

    .experiences-title {
      padding-bottom: 55px;
      font-size: 30px;
      line-height: 40px;
      font-weight: 400;

    }

    .see-all-experiences-button {
      display: inline-block;

      color: $text-dark;
      font-weight: 600;
      font-size: 16px;
      line-height: 45px;
      text-align: center;
      text-decoration: none;
      border: 2px solid $text-dark;
      min-width: 135px;
      height: 48px;

      > span {
        padding: 0 23px;
        width: fit-content;
      }
    }

    .see-all-button{
      display: none;
    }
  }
}

@media (min-width: $md-size){
  #home-page{
    .experiences {
      padding: 120px 0 110px;
    }
  }
}

@media (max-width: $md-size) {
  #home-page {
    .experiences {
      margin-top: -145px;
      .experiences-title {
        font-size: 24px;
        padding-bottom: 28px;
      }
      a{
        text-decoration: none;
      }
    }
  }
}

@media (max-width: $sm-size) {
  #home-page {
    .experiences {
      margin-top: 50px;
      .see-all-experiences-button{
        display: none;
      }
      a{
        text-decoration: none;
        .see-all-button{
          display: block;
          padding: 14px 0;
          margin-bottom: 60px;
          color: $text-dark;
        }
      }

      .experiences-title{
        margin: auto;
      }
    }
  }
}
