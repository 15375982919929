.btn-primary{
  &:hover{
	opacity: 0.8;
  }
}

.btn{
  &.loading {
	color: transparent !important;
	min-height: 1.6rem;
	pointer-events: none;
	position: relative;

	&::after {
	  animation: loading 500ms infinite linear;
	  border: .2rem solid white;
	  border-radius: .8rem;
	  border-right-color: transparent;
	  border-top-color: transparent;
	  content: "";
	  display: block;
	  height: 1.2rem;
	  left: 50%;
	  margin-left: -.8rem;
	  margin-top: -.8rem;
	  position: absolute;
	  top: 50%;
	  width: 1.2rem;
	}
  }
}

@keyframes loading {
  0% {
	transform: rotate(0deg);
  }
  100% {
	transform: rotate(360deg);
  }
}
