@import '../../styles/_variables';
@import '../../styles/_animations';

.experience-list {
  font-family: $proxima-nova;
  color: $text-dark;
  opacity: 0;

  a{
    text-decoration: none;
    color: $dark;
  }

  &.visible {
    animation-duration: 1s;
    animation-fill-mode: both;
    -webkit-animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-name: fadeInUp;
    -webkit-animation-name: fadeInUp;
  }

  .bright-mobile-slider {
    width: 100%;
  }
  .slider {
    min-height: 420px;

    .slider-control-centerright {
      right: -70px !important;
      cursor: pointer;
    }

    .slider-control-centerleft {
      left: -70px !important;
      cursor: pointer;
    }

    .control {
      opacity: 1;
      transition: opacity 0.4s ease;

      &.disabled {
        opacity: 0;
      }
    }
  }
}

@media (max-width: $md-size) {
  .experience-list {
    .experience-item {
      padding-bottom: 38px;
      .btn{
        margin-top: 10px;
        padding-top: 8px;
        padding-bottom: 8px;
      }
    }
  }
}

@media (min-width: $md-size) {
  .slider-list{
    .slider-slide{
      .experience-item{
        margin: 0 15.5px;
      }
    }
  }
}
