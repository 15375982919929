@import '../../styles/_variables';

#home-page {
  .latest-news {
    overflow: hidden;
    background-color: $blue;

    .latest-news-wrapper {
      width: 100%;
      border-radius: 5px;
      padding: 75px 50px;
      z-index: 10;

      .latest-news-title {
        padding-bottom: 32px;

        p {
          font-family: $proxima-nova;
          line-height: 30px;
          font-weight: 600;
          font-size: 25px;
          color: white;

          strong {
            font-weight: bold;
          }
        }
      }
    }


    .latest-news-background {
      height: 270px;
      width: 100%;
      position: relative;

      .phibi {
        position: absolute;
        content: '';
        height: 100%;
        width: 347px;
        right: 0;
        bottom: -40px;
        z-index: 1000;
      }
    }

	.input-container {

	  position: relative;
	  overflow: hidden;
	  max-width: 410px;
	  user-select: none;


	  .text-box{
		position: absolute;
		width: 100%;
		.text-box-input{
		  width: 100%;
		  padding: 12px 25px;
		  border-radius: 30px;
		  //border: 4px solid white;
		  border: none;
		  color: #162B24;
		  font-family: $nunito;
		  font-size: 16px;
		  font-weight: 600;
		  line-height: 32px;
		  text-shadow: 0 1px 1px 0 rgba(31,111,43,0.12);
		}
	  }

	  button{
		height: 55px;
		position: absolute;
		right:0;
		top: 0;
		width: 30%;
		background-color: $blue;
		border: 4px solid white;
		border-radius: 30px;
		color:white
	  }


	  .success-message{
		position: absolute;
		left: 0;
		top:0;
		bottom: 0;
		right: 0;
		background-color: $blue;
		height: 55px;
		display: inline-flex;
		opacity: 0;
		z-index: -1;
		color: white;
		font-family: $nunito;
		padding: 14px 25px;
		border-radius: 30px;
		border: 4px solid white;
		transform: translateX(255px);
		transition: all .4s ease-in;
		line-height: 16px;
		font-weight: bold;
		.message{
		  padding-left: 15px;
		}
		.check-icon{
		  img{
			height: 16px;
			width: 16px;
		  }
		}
	  }

	  &.submitted{
		.success-message{
		  opacity: 1;
		  z-index: 10;
		  transform: translateX(0);
		}
	  }


	  .error-message{
		position: absolute;
		left: 0;
		top:0;
		bottom: 0;
		right: 0;
		background-color: red;
		height: 55px;
		display: inline-flex;
		opacity: 0;
		z-index: -1;
		color: white;
		font-family: $nunito;
		padding: 14px 25px;
		border-radius: 30px;
		border: 4px solid white;
		transform: translateX(255px);
		transition: all .7s ease;
		font-weight: bold;
		line-height: 16px;
		span{
		  padding-left: 20px;
		  font-size: 16px;
		  font-family: $nunito;
		  line-height: 16px;
		}
	  }

	  &.hasFormError{
		.error-message{
		  opacity: 1;
		  z-index: 10;
		  transform: translateX(0);
		}
	  }


	}

  }
}

@media (min-width: $sm-size) {
  #home-page {
    .latest-news {
      .latest-news-wrapper {
        .input-container {
          button {
            background-color: $blue;
            min-width: 147px;
          }
        }
      }

      .latest-news-background {
        &:before {
          position: absolute;
          content: '';
          top: -200px;
          left: -200px;
          background: url('../../assets/images/home/subscribe/layer_left.svg')
            no-repeat center;
          background-size: contain;
          height: 647.95px;
          width: 690.62px;
        }

        &:after {
          position: absolute;
          content: '';
          top: -200px;
          right: -250px;
          background: url('../../assets/images/home/subscribe/layer_right.svg')
            no-repeat center;
          background-size: contain;
          height: 647.95px;
          width: 690.62px;
          z-index: 100;
        }
      }

      .input-container {
		height: 55px;
		user-select: none;
      }

    }
  }
}

@media (max-width: $md-size) and (min-width: $sm-size){
  #home-page{
    .latest-news{
      .latest-news-background{
        .phibi{
          right: -40px;
        }
      }
    }
  }
}

@media (max-width: $sm-size) {
  #home-page {
    .latest-news {
      .latest-news-background {
        height: 501px;

        .phibi {
          height: 199px;
          width: 278px;
          bottom: -2px;
          left: 50%;
          transform: translateX(-50%);

          img {
            height: 100%;
            width: 100%;
          }
        }
      }

      .latest-news-wrapper {
        padding: 40px 0 100px 0;

        .latest-news-title {
          p {
            text-align: center;
            font-size: 24px;
            font-weight: 600;
            line-height: 28px;
          }
        }
      }

      .input-container {
        width: 100%;
		max-width: 100%;
		height: 200px;
		user-select: none;

        .text-box {
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          width: 100%;
        }

        button {
          top: 65px;
          width: 100%;
          left: 50%;
          transform: translateX(-50%);
          border: 2px solid white;
        }

		.success-message,
		.error-message{
		  border: 2px solid white;
		}

		&.submitted {
		  button {
			opacity: 0;
			pointer-events: none;
		  }
		}

      }
    }
  }
}
